import React, { useState, useEffect } from 'react';
import './App.css';
import Nav from './Nav.js';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51HYDjWG7WWg1viiI9OFQhYnX9iM3Wps7lBU2lUtp29HgJhJIypuDASpSdTary1qUIayd6wxwc0XrZ1uo0hYfTCTZ00T2279Yth');
const reEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function Pricing(){
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    var email = params.get('email');
    if (!email){
      return
    }
    setEmail(email.toLowerCase());
  }, []);

  // These are bullets that all plans have in common
  var bullets = [
    'Unlimited data sources',
    'Unlimited requests',
    'Reference cells in requests',
    '=RUN() custom function',
    'Filter results',
    'OAuth2 integrations',
  ];

  useEffect(() => {
    setValidEmail(reEmail.test(email));    
  }, [email]);

  function handleEmailChange(event) {
    setEmail(event.target.value.toLowerCase());
  }

  const handleClick = async (event) => {
    if(!validEmail){
      return
    }    
    
    const stripe = await stripePromise;
    stripe.redirectToCheckout({
      lineItems: [
        {price: 'price_1HYigMG7WWg1viiI4ApLb7sF', quantity: 1}
      ],
      mode: 'subscription',
      successUrl: 'https://dataconnector.app',
      cancelUrl: 'https://dataconnector.app',
      customerEmail: email,
    }).then(function (result) {
      console.log(result.error.message);
    });
  };

  return (
  <>
    <style>
    @import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css);
    </style>
    <div class="relative bg-white	overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-full lg:w-full lg:pb-28 xl:pb-32">
          <Nav />
          <div class="relative bg-white	overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-full lg:w-full lg:pb-28 xl:pb-32">
                <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-14">
                  <div class="sm:text-center lg:text-left">
                    <div class="w-full mx-auto bg-white px-5 py-10 text-gray mb-10">
                      <div class="text-center max-w-xl mx-auto">
                        <h1 class="text-5xl md:text-6xl font-bold mb-5">Pricing</h1>
                        {/*<h3 class="text-xl font-medium mb-10"></h3>*/}
                      </div>
                      <div class="max-w-full mx-auto md:flex">
                        <div class="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-6 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">
                          <div class="w-full flex-grow">
                            <h2 class="text-center font-bold uppercase mb-4">Free Forever</h2>
                            <h3 class="text-center font-bold text-4xl mb-5">Free</h3>
                            <ul class="text-sm px-5 mb-8">
                              {bullets.map((bullet) =>
                                <li class="leading-tight"><i class="mdi mdi-check-bold text-lg"></i> {bullet}</li>
                              )}
                              <li class="leading-tight text-indigo-600"><i class="mdi mdi-check-bold text-lg"></i> 1 saved request</li>
                            </ul>
                          </div>
                          <div class="w-full">
                            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={()=> window.location.href='https://workspace.google.com/marketplace/app/appname/529655450076'}>Get Started for Free Now</button>
                          </div>
                        </div>
                        <div class="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:-mx-3 md:mb-0 rounded-md shadow-lg shadow-gray-600 md:relative md:z-50 md:flex md:flex-col">
                          <div class="w-full flex-grow">
                            <h2 class="text-center font-bold uppercase mb-4">Pro</h2>
                            <h3 class="text-center font-bold text-4xl md:text-5xl mb-5">$9.99/month</h3>
                            <ul class="text-sm px-5 mb-8">
                              {bullets.map((bullet) =>
                                <li class="leading-tight"><i class="mdi mdi-check-bold text-lg"></i> {bullet}</li>
                              )}
                              <li class="leading-tight text-indigo-600"><i class="mdi mdi-check-bold text-lg"></i> Unlimited saved requests</li>
                            </ul>
                          </div>
                          <div class="w-full">
                            <button class="font-bold bg-gray-600 hover:bg-gray-700 text-white rounded-md px-10 py-2 transition-colors w-full" onClick={handleClick} disabled={validEmail ? false : true}>Subscribe Now</button>
                          </div>
                        </div>
                        <div class="w-full md:w-1/3 md:max-w-none bg-white px-8 md:px-10 py-8 md:py-10 mb-3 mx-auto md:my-6 rounded-md shadow-lg shadow-gray-600 md:flex md:flex-col">
                          <div class="w-full flex-grow">
                            <h2 class="text-center font-bold uppercase mb-4">Team</h2>
                            <h3 class="text-center font-bold text-4xl mb-5">$8.99/user/month</h3>
                            <ul class="text-sm px-5 mb-8">
                              {bullets.map((bullet) =>
                                <li class="leading-tight"><i class="mdi mdi-check-bold text-lg"></i> {bullet}</li>
                              )}
                              <li class="leading-tight text-indigo-600"><i class="mdi mdi-check-bold text-lg"></i> Unlimited saved requests</li>
                              <li class="leading-tight text-indigo-600"><i class="mdi mdi-check-bold text-lg"></i> Access for your entire team</li>
                              <li class="leading-tight text-indigo-600"><i class="mdi mdi-check-bold text-lg"></i> Share requests with your team</li>
                            </ul>
                          </div>
                          <div class="w-full">
                            <button class="font-bold bg-gray-600 hover:bg-gray-600 text-white rounded-md px-10 py-2 transition-colors w-full" disabled>Coming soon!</button>
                          </div>
                        </div>
                      </div>
                      <div class="max-w-full mx-auto md:flex pt-14">
                        <div class="relative z-0 w-1/3 mb-5"></div>
                        <div class="relative z-0 w-1/3 mb-5">
                          <h3>Your Google Sheets email address</h3>
                          <input
                            id="email"
                            type="email"
                            name="email"
                            placeholder=""
                            class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                            defaultValue={email}
                            onChange={handleEmailChange}
                          />                        
                          <span id="error" className={`text-sm text-red-600 ${validEmail ? 'hidden':''}`}>A valid Google Sheets email address is required</span>
                        </div>
                        <div class="relative z-0 w-1/3 mb-5"></div>
                      </div>
                    </div>                    
                  </div>                  
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Pricing;