import logo from './logo.svg';
import './App.css';

import ReactTypingEffect from 'react-typing-effect';
import { FcCheckmark } from 'react-icons/fc';
import { VscChromeClose, VscJson } from 'react-icons/vsc';
import { FaGithub,  FaGlobeAmericas, FaDatabase, FaFilter, FaFileExport } from 'react-icons/fa';
import {BiLink} from 'react-icons/bi';
import { GiSpyglass } from 'react-icons/gi';
import { BsQuestion } from 'react-icons/bs';

import Nav from './Nav.js'; 

const competitors = ['Supermetrics', 'API Connector', 'Apipheny', 'KPIBees', 'Flex.io', 'ImportJSON / ImportFromWeb'];
const tagLines = [
  'Lacks too many important features to justify high price.',
  'Reasonably-priced. Lacks filters and database connections.',
  'Low-end price for low-end features.',
  'Lots of features but very restrictive limits. Expensive to upgrade.',
  'Lacks important features. Very expensive.',
  'Very expensive if you want both JSON and Web',
];

function App() {
  return (
    <>
     <div class="relative bg-white	overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <Nav />
          <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-14">
            <div class="sm:text-center lg:text-left">
              <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span class="block xl:block">Data Connector</span>
              </h1>
              <span class="block xl:block text-5xl leading-snug">Connect to</span>
              <span class="block xl:block text-5xl text-indigo-600 leading-none">
                <ReactTypingEffect typingDelay={600} speed={100} eraseDelay={250} text={["JSON APIs", "XML APIs", "CSV data*", "Websites*", "PostgreSQL*", "MySQL*"]} />
              </span>
              <span class="block xl:block text-5xl leading-snug">in Google Sheets</span>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div class="rounded-md shadow">
                  <a href="https://workspace.google.com/marketplace/app/appname/529655450076" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-100 bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    Get started for free
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a href="" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-500 bg-gray-100 hover:bg-gray-200 md:py-4 md:text-lg md:px-10">
                    <FaGithub size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />View the code
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={process.env.PUBLIC_URL + '/hero.jpg' } alt="" />
      </div>
    </div>

    <section id="features" class="relative z-10 text-center max-w-screen-lg xl:max-w-screen-xl mx-auto">
      <div class="px-4 sm:px-6 md:px-8">
        <h2 class="text-3xl sm:text-4xl lg:text-5xl leading-none font-extrabold text-gray-900 tracking-tight mb-8">How we stack up</h2>
      </div>
    </section>

    <div class="px-4 py-2 flex justify-between items-center bg-white sm:py-4 sm:px-10 sm:items-baseline">
      <table class="border-collapse w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="p-3 font-bold text-gray-500 border border-gray-300 hidden lg:table-cell"></th>
            <th class="p-3 font-bold text-gray-500 border border-gray-300 hidden lg:table-cell">
              Data Connector
              <div class="text-xs font-medium text-gray-500">Clear privacy winner. Best value proposition.</div>
            </th>
            {competitors.map((competitor,idx) =>
            <th class="p-3 font-bold text-gray-500 border border-gray-300 hidden lg:table-cell">
              {competitor}
              <div class="text-xs font-medium text-gray-500">{tagLines[idx]}</div>              
            </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static ">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <FaGithub size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Open Source
              </div>
              <div class="text-sm text-gray-500">
                View our code. Optionally, run your own instance.
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />
              </div>
            </td>
            {competitors.map((competitor) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
                <VscChromeClose size={28} style={{color: 'red',margin: 'auto'}} />
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <GiSpyglass size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Privacy-first
              </div>
              <div class="text-sm text-gray-500">
                API Keys and other data protected with AES-256 encryption
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />
              </div>
            </td>
            {competitors.map((competitor) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
                <BsQuestion size={32} style={{color: '#333',margin: 'auto'}} />
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <VscJson size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Connect to JSON/XML/CSV APIs
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />CSV Coming soon
              </div>
            </td>
            {competitors.map((competitor, idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
              {idx === 0 || idx === 2 ? (<><FcCheckmark size={28} style={{margin: 'auto'}} />No XML</>)
               : idx===4 ? (<><FcCheckmark size={28} style={{margin: 'auto'}} />Only CSV</>)
               : idx===5 ? (<><FcCheckmark size={28} style={{margin: 'auto'}} />Only JSON</>)
               : (<FcCheckmark size={28} style={{margin: 'auto'}} />)
              }
              </div>           
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <BiLink size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Connect with OAuth2
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />
              </div>
            </td>
            {competitors.map((competitor,idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
                {idx === 0 || idx === 1 || idx === 3 ? (<><FcCheckmark size={28} style={{margin: 'auto'}} /></>)
                : (<VscChromeClose size={28} style={{color: 'red',margin: 'auto'}} />)
                }
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <FaGlobeAmericas size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Import from websites
              </div>
              <div class="text-sm text-gray-500">
                Import from JavaScript-rendered websites.
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />
              </div>
            </td>
            {competitors.map((competitor, idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
              {idx === 3 || idx === 5 ?
                <FcCheckmark size={28} style={{margin: 'auto'}} />
              :
                <VscChromeClose size={28} style={{color: 'red',margin: 'auto'}} />
              }
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <FaDatabase size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Connect to PostgreSQL/MySQL/other databases
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />Coming soon
              </div>
            </td>
            {competitors.map((competitor, idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
              {idx === 0 || idx === 3 ? (<FcCheckmark size={28} style={{margin: 'auto'}} />)
              : idx === 4 ? (<><FcCheckmark size={28} style={{margin: 'auto'}} />Only MySQL</>)
              : <VscChromeClose size={28} style={{color: 'red',margin: 'auto'}} />
              }
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <FaFilter size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />JMESPath, XPath, JQ, JSONPath filters
              </div>
              <div class="text-sm text-gray-500">
                Select the item(s) you want to import
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />JMESPath. Others coming soon
              </div>
            </td>
            {competitors.map((competitor,idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
              {idx===0 || idx===3 ? (
                <><FcCheckmark size={28} style={{margin: 'auto'}} />JSONPath only</>
              ) : idx===1 ? (
                <><FcCheckmark size={28} style={{margin: 'auto'}} />JMESPath only</>
              ) : idx===5 ? (
                <><FcCheckmark size={28} style={{margin: 'auto'}} />XPATH only</>
              ) : (
                <VscChromeClose size={28} style={{color: 'red',margin: 'auto'}} />
              )}
              </div>
            </td>
            )}
          </tr>
          <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td class="w-full lg:w-auto p-3 text-gray-800 text-right border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold"></span>
              <div class="text-sm font-medium text-gray-900">
                <FaFileExport size={28} style={{ display: 'inline', marginRight: '10px', color: 'currentColor' }} />Use in Sheets/Airtable/Slack
              </div>
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">Data Connector</span>
              <div class="text-sm text-gray-900">
                <FcCheckmark size={42} style={{margin: 'auto'}} />Airtable & Slack Coming soon
              </div>
            </td>
            {competitors.map((competitor,idx) =>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 px-2 py-1 text-xs font-bold">{ competitor }</span>
              <div class="text-sm text-gray-900">
                <><FcCheckmark size={28} style={{margin: 'auto'}} />Only Sheets</>
              </div>
            </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
    <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-sm sm:max-w-xl sm:mx-auto md:mt-5 md:text-sm lg:mx-0 px-10">
      *Coming soon
    </p>
    </>
  );
}

export default App;
