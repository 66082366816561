import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route } from 'react-router-dom';

import App from './App';
import Pricing from './Pricing.js';
import Privacy from './Privacy.js';
import Terms from './Terms.js';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route exact path="/" >
        <App />
      </Route>
      <Route exact path="/pricing" >
        <Pricing />
      </Route>
      <Route exact path="/privacy" >
        <Privacy />
      </Route>
      <Route exact path="/terms" >
        <Terms />
      </Route>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
