import './App.css';
import Nav from './Nav.js'; 

function Privacy(){
  return (
  <>
    <div class="relative bg-white	overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <Nav />
          <div class="relative bg-white	overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-14">
                  <div class="sm:text-center lg:text-left">
                    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <span class="block xl:block">Privacy Policy</span>
                    </h1>
                    <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-md lg:mx-0">
                      Our privacy policy is simple: we only store what we need in order to run this service and we don't sell user data. We believe your API Keys and other user data should stay between you and your API provider. As part of the sign-in process, Google shares with us your email address and we use that to save and retrieve your requests. We also request 3 additional OAuth2 scopes:<br/><br/>                      
                      <strong>1. Display and run third-party web content in prompts and sidebars inside Google applications</strong><br/>
                      This scope is used to display the sidebar; where you can create and edit your saved commands.<br/>
                      
                      <strong>2. Connect to an external service</strong><br/>
                      We request this scope to connect to an external service; which is our backend service that runs your commands.<br/>
                      
                      <strong>3. See, edit, create, and delete your spreadsheets in Google Drive</strong><br/>
                      We request this scope to write the returned data to your Sheet.<br/>
                    </p>
                    <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-md lg:mx-0">
                      All saved requests (including filters) use strong AES-256 encryption. You can verify this by looking at our code at. Further, users can choose to parameterize their requests so that the data we store excludes that information all-together. Similar Add-ons claim to be privacy-focused but ours is the only one you can verify. If you still don't believe us, please run your own instance.
                    </p>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Privacy;
